import React, { useState } from 'react';
import { QrcodeOutlined, VideoCameraOutlined, AlignLeftOutlined, FileTextOutlined } from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import RequestForm from './RequestForm';
import RequestPR from './RequestPR';
import QRCode from './QRCode';
import RequestMaterial from './RequestMaterial';

const { Header, Content, Footer, Sider } = Layout;

const SideNavTwo = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [selectedMenu, setSelectedMenu] = useState('1');
  const [collapsed, setCollapsed] = useState(false); 

  const handleMenuSelect = (e) => {
    setSelectedMenu(e.key);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer, position: 'fixed', zIndex: 1000, width: '100%' }}>
          <img
            className=""
            src="/img/logo.png"
            style={{ marginLeft: '30px', marginTop: '10px', marginBottom: '10px' }}
            alt="logo"
          />
        </Header>
        <Layout style={{ marginTop: 64 }}>
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={toggleCollapsed} 
            collapsed={collapsed} 
            style={{
              position: 'fixed',
              zIndex: 999,
              minHeight: '100vh',
              background: colorBgContainer,
            }}
          >
            <div className="demo-logo-vertical" style={{ marginTop: '20px' }} />
            <Menu theme="white" mode="inline" defaultSelectedKeys={['1']} onSelect={handleMenuSelect} selectedKeys={[selectedMenu]}>
              <p style={{ margin: '10px 25px 0'}}></p>
              {/* <Menu.Item key="1" icon={<FileTextOutlined />}>
                Form Pengajuan
              </Menu.Item>
              <p style={{ margin: '10px 25px 0',fontSize:'12px'}}>Material & Non Material</p> */}
              <Menu.Item key="1" icon={<FileTextOutlined />}>
                Form Pengajuan
              </Menu.Item>
              <Menu.Item key="2" icon={<AlignLeftOutlined />}>
                Tambah PR/PO
              </Menu.Item>
              <Menu.Item key="3" icon={<QrcodeOutlined />}>
                QR Code
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout style={{ marginLeft: collapsed ? 80 : 200 }}>
            <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
              {/* {selectedMenu === '1' && <RequestForm />} */}
              {selectedMenu === '1' && <RequestMaterial />}
              {selectedMenu === '2' && <RequestPR />}
              {selectedMenu === '3' && <QRCode />}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default SideNavTwo;